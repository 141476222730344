/* Añadir margen inferior para separar la barra de búsqueda del navbar */
.search-bar {
    margin: 20px; /* Separación de 20px alrededor de la barra de búsqueda */
    display: flex;
    justify-content: center; /* Centrar la barra de búsqueda horizontalmente */
}

.search-bar input {
    padding: 10px;
    margin-right: 10px;
    width: 300px;
    border-radius: 10px;
}

.search-bar select {
    padding: 10px;
    margin-right: 10px;
    border-radius: 10px;
}

/* Estilo del botón de buscar */
.search-bar button {
    background-color: #51B6A0;  /* Color de fondo igual al de la cabecera */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
    /*transition: background-color 0.3s ease;  /* Transición suave en el hover */
}

/* Hover del botón de buscar */
.search-bar button:hover {
    background-color: #009879;  /* Color más oscuro al hacer hover */
}