/* General styles for the navbar */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    padding: 10px 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Navbar logo section */
.navbar-logo {
    display: flex;
    align-items: center;
}

.navbar-logo img {
    height: 40px;
    margin-right: 10px;
}

.navbar-logo h1 {
    font-size: 24px;
    color: #333;
}

/* Links in the navbar */
.navbar-links {
    display: flex;
    list-style: none;
}

.navbar-links li {
    margin-left: 20px;
}

.navbar-links a {
    text-decoration: none;
    color: #333;
    font-weight: bold;
    font-size: 16px;
    transition: color 0.3s ease;
}

.navbar-links a:hover {
    color: #007bff; /* Color change on hover */
}