/* Añadir padding para separar la tabla del borde de la página */
.search-results {
    padding: 20px; /* Espacio de 20px alrededor de la tabla */
    max-width: 95%; /* Ancho máximo de la tabla */
    max-height: 800px; /* Establecer una altura máxima para la tabla */
    overflow-y: auto; /* Habilitar el scroll vertical dentro de la tabla */
    overflow-x: auto;
}

/* General styles for the table */
.styled-table {
    width: 100%; /* Ancho de la tabla al 100% */
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 400px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

/* Resto del código de estilos para la tabla (ya modificado previamente)... */

/* General styles for the table */
.styled-table {
    width: 100%; /* Ancho de la tabla al 100% */
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 400px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.styled-table thead {
    background-color: #009879;
    color: #ffffff;
    position: sticky; /* Fijar el encabezado de la tabla */
    top: 0;
    
}

/* Styles for the table header */
.styled-table thead tr {
    background-color: #009879;
    color: #ffffff;
    text-align: center;  /* Centra el texto del encabezado */
}

.styled-table th {
    padding: 12px 15px;
    border-left: 2px solid #ffffff;  /* Bordes verticales visibles */
    border-right: 2px solid #ffffff; /* Bordes verticales visibles */
    position: relative;
}

.styled-table th:first-child {
    border-top-left-radius: 10px;  /* Borde superior izquierdo redondeado */
}

.styled-table th:last-child {
    border-top-right-radius: 10px;  /* Borde superior derecho redondeado */
}

/* Ocultar los bordes horizontales del encabezado */
.styled-table th {
    border-top: none;
    border-bottom: none;
}

/* Body of the table */
.styled-table tbody td {
    padding: 12px 15px;
    text-align: center; /* Centrar texto en las celdas del cuerpo */
    border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
    border-bottom: 2px solid #009879;
}

.styled-table {
    width: 100%;
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 400px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.styled-table th {
    padding: 12px 15px;
    background-color: #009879;
    color: white;
    cursor: pointer;
}

.styled-table th.asc:after {
    content: " 🔼";
}

.styled-table th.desc:after {
    content: " 🔽";
}

.styled-table td {
    padding: 12px 15px;
    border-bottom: 1px solid #dddddd;
    text-align: center;
}

.styled-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
    border-bottom: 2px solid #009879;
}