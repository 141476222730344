/* Contenedor del formulario de login */
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f3f3f3;
    padding: 20px;
}

/* Estilo del formulario */
.login-form {
    background-color: #ffffff;
    padding: 30px 40px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
}

/* Estilo de los encabezados */
.login-form h2 {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
    color: #333;
    text-align: center;
}

/* Estilo de los encabezados */
.login-form h3 {
    margin-bottom: 20px;
    font-size: 20px;
    color: #6e6e6e;
    text-align: center;
}

/* Estilo de los inputs del formulario */
.login-form input {
    width: 92%;
    padding: 12px 15px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    font-size: 16px;
}

/* Estilo del botón de login */
.login-form button {
    width: 100%;
    background-color: #51B6A0;  /* Color de fondo similar al de otros botones */
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

/* Hover del botón de login */
.login-form button:hover {
    background-color: #009879;  /* Color más oscuro al hacer hover */
}

/* Estilo para los mensajes de error */
.login-form .error-message {
    color: #ff4d4f;
    margin-bottom: 20px;
    text-align: center;
}

/* Login logo section */
.login-logo {
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-logo img {
    height: 40px;
}

/* Estilo para hacer que el formulario se vea bien en pantallas pequeñas */
@media (max-width: 600px) {
    .login-form {
        padding: 20px;
    }
    
    .login-form h2 {
        font-size: 20px;
    }

    .login-form button {
        padding: 10px 15px;
    }
}